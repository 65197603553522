/* New adminpanel */

#sidebar {
    padding-top: 15px;
    .tabbutton.btn-primary {
        outline: none;
        &:after {
            position: absolute;
            left: 43%;
            bottom: -12px;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 28px;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: "\F078";
        }
    }
    .overflow-auto {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .resize-handle {
        position: absolute;
        right: 14px;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 4px;
        cursor: col-resize;
        button {
            outline: 0;
            &:focus,
            &:active,
            &:hover {
                outline: 0 !important;
            }
            cursor: col-resize;
            width:100%;
            height:100%;
            text-align: left;
            border-radius: 0;
            padding: 0px 7px 0px 4px;
            i {
                font-size: 12px;
                width: 5px;
            }
        }
    }
    .transition-animate-width {
        -moz-transition: width 0.5s ease;
        -webkit-transition: width 0.5s ease;
        -ms-transition: width 0.5s ease;
        transition: width 0.5s ease;
    }
    .fade-enter-active {
        -moz-transition: all 0.8s ease;
        -webkit-transition: all 0.8s ease;
        -ms-transition: all 0.8s ease;
        transition: all 0.8s ease;
    }
    .fade-leave-active {
        -moz-transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        -webkit-transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        -ms-transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .fade-enter,
    .fade-leave-to {
        -moz-transform: translateY(10px);
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
    }
    .slide-fade-enter-active {
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
        -moz-transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        -webkit-transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        -ms-transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
        -moz-transform: rotateY(90);
        -webkit-transform: rotateY(90);
        -ms-transform: rotateY(90);
        transform: rotateY(90);
        -moz-transform-origin: left;
        -webkit-transform-origin: left;
        -ms-transform-origin: left;
        transform-origin: left;
        opacity: 0;
    }
    .slide-fade-down-enter-active {
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .slide-fade-down-leave-active {
        -moz-transition: all 0.2s cubic-bezier(0, 1, 0.5, 1.0);
        -webkit-transition: all 0.2s cubic-bezier(0, 1, 0.5, 1.0);
        -ms-transition: all 0.2s cubic-bezier(0, 1, 0.5, 1.0);
        transition: all 0.2s cubic-bezier(0, 1, 0.5, 1.0);
    }
    .slide-fade-down-enter,
    .slide-fade-down-leave-to {
        -moz-transform: rotateY(45);
        -webkit-transform: rotateY(45);
        -ms-transform: rotateY(45);
        transform: rotateY(45);
        -moz-transform-origin: left;
        -webkit-transform-origin: left;
        -ms-transform-origin: left;
        transform-origin: left;
        opacity: 0;
    }
    div[class^="col-"],
    div[class*=" col-"] {
        &.nofloat {
            float: none;
        }
    }
    .button-sub-bar {
        min-height: 32px;
    }
    &.fill-height,
    .fill-height {
        height: 100%
    }
    .dragPointer {
        cursor: move;
    }
    .question-question-list {
        .question-question-list-item {
            padding: 0;
            .question-question-list-item-drag {
                margin: 10px 15px;
            }
            &.selected {
                padding-left: 20px;
                .question-question-list-item-drag {
                    margin: 10px 10px 10px 5px;
                }
            }
            .question-question-list-item-link {
                padding: 10px 25px 10px 10px;
                display: inline-flex;
                flex-wrap: wrap;
            }
        }
    }
    .question_text_ellipsize {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 2px;
    }
}

.bigIcons {
    font-size: 18px;
    line-height: 21px;
    height: 24px;
}

.quickmenuIcon {
    font-size: "28px";
}

.ls-ba {
    .list-group {
        padding-right: 0;
        >.list-group-item {
            padding-right: 0;
            .list-group {
                margin-bottom: 0;
                .list-group-item {
                    //padding-left:15px;
                    &:last-of-type {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .breadcrumb>li+li:before {
        padding: 0 5px;
        content: "\f061";
        font: normal normal normal 18px/1 FontAwesome;
    }
}

#pjax-content {
    .side-body {
        min-height: 0;
    }
}

.menubar {
    position: relative;
}

.menubar.surveybar {
    width: 100%;
    margin: 8px auto;
    z-index: 100;
}

#side-menu-container {
    width: 20%;
}

.nooverflow {
    overflow: hidden;
}

.overflow-enabled {
    overflow: auto;
}

.side-body {
    min-height: 400px;
}

footer.footer {
    position: static;
}

ol.breadcrumb.title-bar-breadcrumb {
    margin-bottom: 0px;
    > :first-child {
        a {
            &.animate::after {
                margin-left: 0px;
            }
        }
    }
    a {
        text-decoration: none;
        line-height: 24px;
        font-size: 18px;
        &.animate::after {
            text-align: right;
            content: '';
            display: block;
            width: 0;
            height: 2px;
        }
        &.animate:hover::after {
            width: 100%;
        }
        &.animate.home-icon:hover::after {
            margin-left: 0;
            width: 100% !important;
        }
    }
    li {
        line-height: 24px;
        font-size: 18px;
        &.active {
            font-style: italic
        }
        >div {
            display: inline-block;
        }
    }
    .menu-label {
        width: 100%;
        display: block;
        font-size: 85%;
        font-style: normal;
    }
}

.breadcrumb-title {
    line-height: 24px;
    font-size: 18px;
    padding: 8px 15px;
}

#pjax-file-load-container {
    height: 6px;
    position: fixed;
    top: 1px;
    left: 0;
    width: 100%;
    width: 100vw;
    >div {
        display: none;
        height: 8px;
        border-radius: 1px;
    }
}

#pjaxClickInhibitor {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
    width: 100%;
    width: 100vw;
    height: 100%;
    height: 100vh;
    background: rgba(250, 250, 250, 0.4);
    cursor: progress;
}

.makeDisabledInputsTransparent {
    input:disabled {
        color: transparent;
    }
}

.btn-group {
    .btn{
        input[type=radio],
        input[type=checkbox] {
            outline: none;
            margin:0;
            padding: 0;
            border: 0;
            &:after{
                display:none;
            }
            &:before{
                display:none;
            }
        }
    }
}
